import { Player } from "../types";
import { getPlayersWithPoints } from "../utils";

import { Race } from "../types";

export const races: Race[] = [
  {
    id: 1,
    name: "FORMULA 1 GULF AIR BAHRAIN GRAND PRIX 2023",
    trackName: "Bahrain International Circuit",
    country: "Bahrain",
    status: "completed",
    result: {
      pole: "Max Verstappen",
      first: "Max Verstappen",
      last: "Lando Norris",
      fastestLap: "Guanyu Zhou",
    },
  },
  {
    id: 2,
    name: "FORMULA 1 STC SAUDI ARABIAN GRAND PRIX 2023",
    trackName: "Jeddah Corniche Circuit",
    country: "Saudi Arabia",
    status: "completed",
    result: {
      pole: "Sergio Perez",
      first: "Sergio Perez",
      last: "Valtteri Bottas",
      fastestLap: "Max Verstappen",
    },
  },
  {
    id: 3,
    name: "FORMULA 1 ROLEX AUSTRALIAN GRAND PRIX 2023",
    trackName: "Melbourne Grand Prix Circuit",
    country: "Australia",
    status: "completed",
    result: {
      pole: "Max Verstappen",
      first: "Max Verstappen",
      last: "Kevin Magnussen",
      fastestLap: "Sergio Perez",
    },
  },
  {
    id: 4,
    name: "FORMULA 1 AZERBAIJAN GRAND PRIX 2023",
    trackName: "Baku City Circuit",
    country: "Azerbaijan",
    status: "completed",
    result: {
      pole: "Charles Leclerc",
      first: "Sergio Perez",
      last: "Valtteri Bottas",
      fastestLap: "George Russell",
    },
  },
  {
    id: 5,
    name: "FORMULA 1 CRYPTO.COM MIAMI GRAND PRIX 2023",
    trackName: "Miami International Autodrome",
    country: "United States",
    status: "completed",
    result: {
      pole: "Sergio Perez",
      first: "Max Verstappen",
      last: "Logan Sargeant",
      fastestLap: "Max Verstappen",
    },
  },
  {
    id: 6,
    name: "FORMULA 1 QATAR AIRWAYS GRAN PREMIO DEL MADE IN ITALY E DELL'EMILIA-ROMAGNA 2023",
    trackName: "Autodromo Enzo e Dino Ferrari",
    country: "Italy",
    status: "cancelled",
  },
  {
    id: 7,
    name: "FORMULA 1 GRAND PRIX DE MONACO 2023",
    trackName: "Circuit de Monaco",
    country: "Monaco",
    status: "completed",
    result: {
      pole: "Max Verstappen",
      first: "Max Verstappen",
      last: "Kevin Magnussen",
      fastestLap: "Lewis Hamilton",
    },
  },
  {
    id: 8,
    name: "FORMULA 1 AWS GRAN PREMIO DE ESPAÑA 2023",
    trackName: "Circuit de Barcelona-Catalunya",
    country: "Spain",
    status: "completed",
    result: {
      pole: "Max Verstappen",
      first: "Max Verstappen",
      last: "Logan Sargeant",
      fastestLap: "Max Verstappen",
    },
  },
  {
    id: 9,
    name: "FORMULA 1 PIRELLI GRAND PRIX DU CANADA 2023",
    trackName: "Circuit Gilles-Villeneuve",
    country: "Canada",
    status: "completed",
    result: {
      pole: "Max Verstappen",
      first: "Max Verstappen",
      last: "Nyck de Vries",
      fastestLap: "Sergio Perez",
    },
  },
  {
    id: 10,
    name: "FORMULA 1 GROSSER PREIS VON ÖSTERREICH 2023",
    trackName: "Red Bull Ring",
    country: "Austria",
    status: "completed",
    result: {
      pole: "Max Verstappen",
      first: "Max Verstappen",
      last: "Yuki Tsunoda",
      fastestLap: "Max Verstappen",
    },
  },
  {
    id: 11,
    name: "FORMULA 1 ARAMCO BRITISH GRAND PRIX 2023",
    trackName: "Silverstone Circuit",
    country: "Great Britain",
    status: "completed",
    result: {
      pole: "Max Verstappen",
      first: "Max Verstappen",
      last: "Pierre Gasly",
      fastestLap: "Max Verstappen",
    },
  },
  {
    id: 12,
    name: "FORMULA 1 QATAR AIRWAYS HUNGARIAN GRAND PRIX 2023",
    trackName: "Hungaroring",
    country: "Hungary",
    status: "completed",
    result: {
      pole: "Lewis Hamilton",
      first: "Max Verstappen",
      last: "Logan Sargeant",
      fastestLap: "Max Verstappen",
    },
  },
  {
    id: 13,
    name: "FORMULA 1 BELGIAN GRAND PRIX 2023",
    trackName: "Circuit de Spa-Francorchamps",
    country: "Belgium",
    status: "completed",
    result: {
      pole: "Charles Leclerc",
      first: "Max Verstappen",
      last: "Nico Hulkenberg",
      fastestLap: "Lewis Hamilton",
    },
  },
  {
    id: 14,
    name: "FORMULA 1 HEINEKEN DUTCH GRAND PRIX 2023",
    trackName: "Circuit Zandvoort",
    country: "Netherlands",
    status: "completed",
    result: {
      pole: "Max Verstappen",
      first: "Max Verstappen",
      last: "George Russell",
      fastestLap: "Fernando Alonso",
    },
  },
  {
    id: 15,
    name: "FORMULA 1 PIRELLI GRAN PREMIO D’ITALIA 2023",
    trackName: "Autodromo Nazionale Monza",
    country: "Italy",
    status: "completed",
    result: {
      pole: "Carlos Sainz",
      first: "Max Verstappen",
      last: "Kevin Magnussen",
      fastestLap: "Oscar Piastri",
    },
  },
  {
    id: 16,
    name: "FORMULA 1 SINGAPORE AIRLINES SINGAPORE GRAND PRIX 2023",
    trackName: "Marina Bay Street Circuit",
    country: "Singapore",
    status: "completed",
    result: {
      pole: "Carlos Sainz",
      first: "Carlos Sainz",
      last: "George Russell",
      fastestLap: "Lewis Hamilton",
    },
  },
  {
    id: 17,
    name: "FORMULA 1 LENOVO JAPANESE GRAND PRIX 2023",
    trackName: "Suzuka Internarional Racing Course",
    country: "Japan",
    status: "completed",
    result: {
      pole: "Max Verstappen",
      first: "Max Verstappen",
      last: "Kevin Magnussen",
      fastestLap: "Max Verstappen",
    },
  },
  {
    id: 18,
    name: "FORMULA 1 QATAR AIRWAYS QATAR GRAND PRIX 2023",
    trackName: "Lusail International Circuit",
    country: "Qatar",
    status: "completed",
    result: {
      pole: "Max Verstappen",
      first: "Max Verstappen",
      last: "Liam Lawson",
      fastestLap: "Max Verstappen",
    },
  },
  {
    id: 19,
    name: "FORMULA 1 LENOVO UNITED STATES GRAND PRIX 2023",
    trackName: "Circuit of The Americas",
    country: "United States",
    status: "completed",
    result: {
      pole: "Charles Leclerc",
      first: "Max Verstappen",
      last: "Daniel Ricciardo",
      fastestLap: "Yuki Tsunoda",
    },
  },
  {
    id: 20,
    name: "FORMULA 1 GRAN PREMIO DE LA CIUDAD DE MÉXICO 2023",
    trackName: "Autódromo Hermanos Rodríguez",
    country: "Mexico",
    status: "completed",
    result: {
      pole: "Charles Leclerc",
      first: "Max Verstappen",
      last: "Lance Stroll",
      fastestLap: "Lewis Hamilton",
    },
  },
  {
    id: 21,
    name: "FORMULA 1 ROLEX GRANDE PRÊMIO DE SÃO PAULO 2023",
    trackName: "Autódromo José Carlos Pace",
    country: "Brazil",
    status: "completed",
    result: {
      pole: "Max Verstappen",
      first: "Max Verstappen",
      last: "Oscar Piastri",
      fastestLap: "Lando Norris",
    },
  },
  {
    id: 22,
    name: "FORMULA 1 HEINEKEN SILVER LAS VEGAS GRAND PRIX 2023",
    trackName: "Las Vegas",
    country: "United States",
    status: "completed",
    result: {
      pole: "Charles Leclerc",
      first: "Max Verstappen",
      last: "Nico Hulkenberg",
      fastestLap: "Oscar Piastri",
    },
  },
  {
    id: 23,
    name: "FORMULA 1 ETIHAD AIRWAYS ABU DHABI GRAND PRIX 2023",
    trackName: "Yas Marina Circuit",
    country: "Abu Dhabi",
    status: "completed",
    result: {
      pole: "Max Verstappen",
      first: "Max Verstappen",
      last: "Kevin Magnussen",
      fastestLap: "Max Verstappen",
    },
  },
];

const playersWithoutPoints: Player[] = [
  {
    id: 1,
    name: "Aaron R",
    points: 0,
    predictions: {
      "1": {
        pole: "Max Verstappen",
        first: "Max Verstappen",
        last: "Logan Sargeant",
        fastestLap: "Fernando Alonso",
      },
      "2": {
        pole: "Max Verstappen",
        first: "Max Verstappen",
        last: "Nico Hulkenberg",
        fastestLap: "Charles Leclerc",
      },
      "3": {
        pole: "Max Verstappen",
        first: "Max Verstappen",
        last: "Lando Norris",
        fastestLap: "Charles Leclerc",
      },
      "4": {
        pole: "Max Verstappen",
        first: "Max Verstappen",
        last: "Valtteri Bottas",
        fastestLap: "Fernando Alonso",
      },
      "5": {
        pole: "Sergio Perez",
        first: "Max Verstappen",
        last: "Valtteri Bottas",
        fastestLap: "George Russell",
      },
      "7": {
        pole: "Max Verstappen",
        first: "Max Verstappen",
        last: "Logan Sargeant",
        fastestLap: "Carlos Sainz",
      },
      "8": {
        pole: "Fernando Alonso",
        first: "Max Verstappen",
        last: "Kevin Magnussen",
        fastestLap: "Max Verstappen",
      },
      "9": {
        pole: "Max Verstappen",
        first: "Max Verstappen",
        last: "Logan Sargeant",
        fastestLap: "Max Verstappen",
      },
      "10": {
        pole: "Max Verstappen",
        first: "Max Verstappen",
        last: "Logan Sargeant",
        fastestLap: "Max Verstappen",
      },
      "11": {
        pole: "Carlos Sainz",
        first: "Max Verstappen",
        last: "Nyck de Vries",
        fastestLap: "Max Verstappen",
      },
      "12": {
        pole: "Max Verstappen",
        first: "Max Verstappen",
        last: "Kevin Magnussen",
        fastestLap: "Max Verstappen",
      },
      "13": {
        pole: "Lando Norris",
        first: "Max Verstappen",
        last: "Kevin Magnussen",
        fastestLap: "Max Verstappen",
      },
      "14": {
        pole: "Max Verstappen",
        first: "Max Verstappen",
        last: "Liam Lawson",
        fastestLap: "Max Verstappen",
      },
      "15": {
        pole: "Max Verstappen",
        first: "Max Verstappen",
        last: "Kevin Magnussen",
        fastestLap: "Lando Norris",
      },
      "16": {
        pole: "Charles Leclerc",
        first: "Max Verstappen",
        last: "Kevin Magnussen",
        fastestLap: "Lewis Hamilton",
      },
      "17": {
        pole: "Max Verstappen",
        first: "Max Verstappen",
        last: "Kevin Magnussen",
        fastestLap: "Sergio Perez",
      },
      "18": {
        pole: "Carlos Sainz",
        first: "Max Verstappen",
        last: "Kevin Magnussen",
        fastestLap: "Max Verstappen",
      },
      "19": {
        pole: "Max Verstappen",
        first: "Max Verstappen",
        last: "Valtteri Bottas",
        fastestLap: "Max Verstappen",
      },
      "20": {
        pole: "Max Verstappen",
        first: "Max Verstappen",
        last: "Kevin Magnussen",
        fastestLap: "Lewis Hamilton",
      },
      "21": {
        pole: "Charles Leclerc",
        first: "Max Verstappen",
        last: "Kevin Magnussen",
        fastestLap: "Lewis Hamilton",
      },
      "22": {
        pole: "Charles Leclerc",
        first: "Max Verstappen",
        last: "Nico Hulkenberg",
        fastestLap: "Lando Norris",
      },
      "23": {
        pole: "Charles Leclerc",
        first: "Max Verstappen",
        last: "Nico Hulkenberg",
        fastestLap: "Lando Norris",
      },
    },
  },
  {
    id: 2,
    name: "Ollie M",
    points: 0,
    predictions: {
      "1": {
        pole: "Max Verstappen",
        first: "Max Verstappen",
        last: "Alex Albon",
        fastestLap: "Carlos Sainz",
      },
      "2": {
        pole: "Max Verstappen",
        first: "Max Verstappen",
        last: "Guanyu Zhou",
        fastestLap: "Charles Leclerc",
      },
      "3": {
        pole: "Max Verstappen",
        first: "Max Verstappen",
        last: "Lando Norris",
        fastestLap: "Sergio Perez",
      },
      "4": {
        pole: "Max Verstappen",
        first: "Sergio Perez",
        last: "Valtteri Bottas",
        fastestLap: "George Russell",
      },
      "5": {
        pole: "Max Verstappen",
        first: "Max Verstappen",
        last: "Valtteri Bottas",
        fastestLap: "Charles Leclerc",
      },
      "7": {
        pole: "Sergio Perez",
        first: "Max Verstappen",
        last: "Logan Sargeant",
        fastestLap: "Max Verstappen",
      },
      "8": {
        pole: "Fernando Alonso",
        first: "Max Verstappen",
        last: "Logan Sargeant",
        fastestLap: "Lewis Hamilton",
      },
      "9": {
        pole: "Max Verstappen",
        first: "Max Verstappen",
        last: "Logan Sargeant",
        fastestLap: "George Russell",
      },
      "10": {
        pole: "Max Verstappen",
        first: "Max Verstappen",
        last: "Logan Sargeant",
        fastestLap: "Max Verstappen",
      },
      "11": {
        pole: "Max Verstappen",
        first: "Max Verstappen",
        last: "Logan Sargeant",
        fastestLap: "Sergio Perez",
      },
      "12": {
        pole: "Max Verstappen",
        first: "Max Verstappen",
        last: "Valtteri Bottas",
        fastestLap: "Max Verstappen",
      },
      "13": {
        pole: "Oscar Piastri",
        first: "Max Verstappen",
        last: "Guanyu Zhou",
        fastestLap: "Max Verstappen",
      },
      "14": {
        pole: "Max Verstappen",
        first: "Max Verstappen",
        last: "Liam Lawson",
        fastestLap: "George Russell",
      },
      "15": {
        pole: "Max Verstappen",
        first: "Max Verstappen",
        last: "Kevin Magnussen",
        fastestLap: "Max Verstappen",
      },
      "16": {
        pole: "Max Verstappen",
        first: "Max Verstappen",
        last: "Kevin Magnussen",
        fastestLap: "Carlos Sainz",
      },
      "17": {
        pole: "Charles Leclerc",
        first: "Max Verstappen",
        last: "Kevin Magnussen",
        fastestLap: "George Russell",
      },
      "18": {
        pole: "Max Verstappen",
        first: "Max Verstappen",
        last: "Guanyu Zhou",
        fastestLap: "Sergio Perez",
      },
      "19": {
        pole: "Max Verstappen",
        first: "Max Verstappen",
        last: "Guanyu Zhou",
        fastestLap: "Lewis Hamilton",
      },
      "20": {
        pole: "Max Verstappen",
        first: "Max Verstappen",
        last: "Kevin Magnussen",
        fastestLap: "Lando Norris",
      },
      "21": {
        pole: "Max Verstappen",
        first: "Max Verstappen",
        last: "Kevin Magnussen",
        fastestLap: "Lewis Hamilton",
      },
      "22": {
        pole: "Max Verstappen",
        first: "Max Verstappen",
        last: "Valtteri Bottas",
        fastestLap: "Lando Norris",
      },
      "23": {
        pole: "Max Verstappen",
        first: "Max Verstappen",
        last: "Kevin Magnussen",
        fastestLap: "Carlos Sainz",
      },
    },
  },
  {
    id: 3,
    name: "Gary M",
    points: 0,
    predictions: {
      "1": {
        pole: "Max Verstappen",
        first: "Max Verstappen",
        last: "Nico Hulkenberg",
        fastestLap: "Fernando Alonso",
      },
      "2": {
        pole: "Fernando Alonso",
        first: "Max Verstappen",
        last: "Nyck de Vries",
        fastestLap: "Fernando Alonso",
      },
      "3": {
        pole: "Max Verstappen",
        first: "Max Verstappen",
        last: "Alex Albon",
        fastestLap: "Max Verstappen",
      },
      "4": {
        pole: "Max Verstappen",
        first: "Sergio Perez",
        last: "Alex Albon",
        fastestLap: "Max Verstappen",
      },
      "5": {
        pole: "Max Verstappen",
        first: "Sergio Perez",
        last: "Nyck de Vries",
        fastestLap: "Fernando Alonso",
      },
      "7": {
        pole: "Fernando Alonso",
        first: "Lewis Hamilton",
        last: "Alex Albon",
        fastestLap: "Fernando Alonso",
      },
      "8": {
        pole: "Fernando Alonso",
        first: "Fernando Alonso",
        last: "Logan Sargeant",
        fastestLap: "Max Verstappen",
      },
      "9": {
        pole: "Max Verstappen",
        first: "Max Verstappen",
        last: "Alex Albon",
        fastestLap: "Fernando Alonso",
      },
      "10": {
        pole: "Max Verstappen",
        first: "Max Verstappen",
        last: "Nyck de Vries",
        fastestLap: "Fernando Alonso",
      },
      "11": {
        pole: "Max Verstappen",
        first: "Max Verstappen",
        last: "Logan Sargeant",
        fastestLap: "Max Verstappen",
      },
      "12": {
        pole: "Max Verstappen",
        first: "Max Verstappen",
        last: "Logan Sargeant",
        fastestLap: "Lando Norris",
      },
      "13": {
        pole: "Lewis Hamilton",
        first: "Max Verstappen",
        last: "Logan Sargeant",
        fastestLap: "Fernando Alonso",
      },
      "14": {
        pole: "Max Verstappen",
        first: "Max Verstappen",
        last: "Logan Sargeant",
        fastestLap: "Fernando Alonso",
      },
      "15": {
        pole: "Sergio Perez",
        first: "Max Verstappen",
        last: "Logan Sargeant",
        fastestLap: "Max Verstappen",
      },
      "16": {
        pole: "Sergio Perez",
        first: "Max Verstappen",
        last: "Logan Sargeant",
        fastestLap: "Max Verstappen",
      },
      "17": {
        pole: "Charles Leclerc",
        first: "Max Verstappen",
        last: "Kevin Magnussen",
        fastestLap: "Max Verstappen",
      },
      "18": {
        pole: "Carlos Sainz",
        first: "Max Verstappen",
        last: "Valtteri Bottas",
        fastestLap: "Max Verstappen",
      },
      "19": {
        pole: "Max Verstappen",
        first: "Max Verstappen",
        last: "Guanyu Zhou",
        fastestLap: "Oscar Piastri",
      },
      "20": {
        pole: "Max Verstappen",
        first: "Max Verstappen",
        last: "Daniel Ricciardo",
        fastestLap: "Charles Leclerc",
      },
      "21": {
        pole: "Charles Leclerc",
        first: "Max Verstappen",
        last: "Logan Sargeant",
        fastestLap: "Max Verstappen",
      },
      "22": {
        pole: "Lando Norris",
        first: "Max Verstappen",
        last: "Guanyu Zhou",
        fastestLap: "Fernando Alonso",
      },
      "23": {
        pole: "Max Verstappen",
        first: "Max Verstappen",
        last: "Logan Sargeant",
        fastestLap: "Sergio Perez",
      },
    },
  },
  {
    id: 4,
    name: "Luke W",
    points: 0,
    predictions: {
      "1": {
        pole: "Charles Leclerc",
        first: "Max Verstappen",
        last: "Oscar Piastri",
        fastestLap: "Max Verstappen",
      },
      "2": {
        pole: "Max Verstappen",
        first: "Max Verstappen",
        last: "Lando Norris",
        fastestLap: "Max Verstappen",
      },
      "3": {
        pole: "Max Verstappen",
        first: "Max Verstappen",
        last: "Lando Norris",
        fastestLap: "Max Verstappen",
      },
      "4": {
        pole: "Max Verstappen",
        first: "Max Verstappen",
        last: "Nyck de Vries",
        fastestLap: "Sergio Perez",
      },
      "5": {
        pole: "Sergio Perez",
        first: "Max Verstappen",
        last: "Guanyu Zhou",
        fastestLap: "Max Verstappen",
      },
      "7": {
        pole: "Sergio Perez",
        first: "Max Verstappen",
        last: "Guanyu Zhou",
        fastestLap: "Max Verstappen",
      },
      "8": {
        pole: "Max Verstappen",
        first: "Max Verstappen",
        last: "Logan Sargeant",
        fastestLap: "Fernando Alonso",
      },
      "9": {
        pole: "Max Verstappen",
        first: "Max Verstappen",
        last: "Nyck de Vries",
        fastestLap: "Max Verstappen",
      },
      "10": {
        pole: "Max Verstappen",
        first: "Max Verstappen",
        last: "Logan Sargeant",
        fastestLap: "Sergio Perez",
      },
      "11": {
        pole: "Max Verstappen",
        first: "Max Verstappen",
        last: "Nyck de Vries",
        fastestLap: "Max Verstappen",
      },
      "12": {
        pole: "Charles Leclerc",
        first: "Max Verstappen",
        last: "Kevin Magnussen",
        fastestLap: "Max Verstappen",
      },
      "13": {
        pole: "Lando Norris",
        first: "Max Verstappen",
        last: "Kevin Magnussen",
        fastestLap: "Max Verstappen",
      },
      "14": {
        pole: "Max Verstappen",
        first: "Max Verstappen",
        last: "Liam Lawson",
        fastestLap: "Max Verstappen",
      },
      "15": {
        pole: "Max Verstappen",
        first: "Max Verstappen",
        last: "Logan Sargeant",
        fastestLap: "Lando Norris",
      },
      "16": {
        pole: "Carlos Sainz",
        first: "Max Verstappen",
        last: "Kevin Magnussen",
        fastestLap: "Carlos Sainz",
      },
      "17": {
        pole: "Max Verstappen",
        first: "Max Verstappen",
        last: "Kevin Magnussen",
        fastestLap: "Max Verstappen",
      },
      "18": {
        pole: "Max Verstappen",
        first: "Max Verstappen",
        last: "Kevin Magnussen",
        fastestLap: "Max Verstappen",
      },
      "19": {
        pole: "Max Verstappen",
        first: "Max Verstappen",
        last: "Valtteri Bottas",
        fastestLap: "Max Verstappen",
      },
      "20": {
        pole: "Max Verstappen",
        first: "Max Verstappen",
        last: "Kevin Magnussen",
        fastestLap: "Lando Norris",
      },
      "21": {
        pole: "Carlos Sainz",
        first: "Max Verstappen",
        last: "Guanyu Zhou",
        fastestLap: "Lewis Hamilton",
      },
      "22": {
        pole: "Charles Leclerc",
        first: "Max Verstappen",
        last: "Guanyu Zhou",
        fastestLap: "Max Verstappen",
      },
      "23": {
        pole: "Charles Leclerc",
        first: "Max Verstappen",
        last: "Nico Hulkenberg",
        fastestLap: "Max Verstappen",
      },
    },
  },
  {
    id: 5,
    name: "Harrison R",
    points: 0,
    predictions: {
      "1": {
        pole: "Max Verstappen",
        first: "Max Verstappen",
        last: "Logan Sargeant",
        fastestLap: "Max Verstappen",
      },
      "2": {
        pole: "Max Verstappen",
        first: "Max Verstappen",
        last: "Nyck de Vries",
        fastestLap: "Fernando Alonso",
      },
      "3": {
        pole: "Max Verstappen",
        first: "Max Verstappen",
        last: "Guanyu Zhou",
        fastestLap: "Max Verstappen",
      },
      "4": {
        pole: "Max Verstappen",
        first: "Max Verstappen",
        last: "Nyck de Vries",
        fastestLap: "Lewis Hamilton",
      },
      "5": {
        pole: "Max Verstappen",
        first: "Max Verstappen",
        last: "Kevin Magnussen",
        fastestLap: "Fernando Alonso",
      },
      "7": {
        pole: "Charles Leclerc",
        first: "Sergio Perez",
        last: "Nyck de Vries",
        fastestLap: "Max Verstappen",
      },
      "8": {
        pole: "Max Verstappen",
        first: "Max Verstappen",
        last: "Nyck de Vries",
        fastestLap: "Lewis Hamilton",
      },
      "9": {
        pole: "Charles Leclerc",
        first: "Max Verstappen",
        last: "Logan Sargeant",
        fastestLap: "Max Verstappen",
      },
      "10": {
        pole: "Max Verstappen",
        first: "Max Verstappen",
        last: "Nyck de Vries",
        fastestLap: "Max Verstappen",
      },
      "11": {
        pole: "Max Verstappen",
        first: "Max Verstappen",
        last: "Nyck de Vries",
        fastestLap: "Max Verstappen",
      },
      "12": {
        pole: "Max Verstappen",
        first: "Max Verstappen",
        last: "Nico Hulkenberg",
        fastestLap: "Max Verstappen",
      },
      "13": {
        pole: "Lewis Hamilton",
        first: "Max Verstappen",
        last: "Nico Hulkenberg",
        fastestLap: "Max Verstappen",
      },
      "14": {
        pole: "Max Verstappen",
        first: "Max Verstappen",
        last: "Nico Hulkenberg",
        fastestLap: "Lewis Hamilton",
      },
      "15": {
        pole: "Max Verstappen",
        first: "Max Verstappen",
        last: "Guanyu Zhou",
        fastestLap: "Charles Leclerc",
      },
      "16": {
        pole: "Max Verstappen",
        first: "Max Verstappen",
        last: "Guanyu Zhou",
        fastestLap: "Max Verstappen",
      },
      "17": {
        pole: "Max Verstappen",
        first: "Max Verstappen",
        last: "Logan Sargeant",
        fastestLap: "Charles Leclerc",
      },
      "18": {
        pole: "Max Verstappen",
        first: "Max Verstappen",
        last: "Kevin Magnussen",
        fastestLap: "Lando Norris",
      },
      "19": {
        pole: "Max Verstappen",
        first: "Max Verstappen",
        last: "Logan Sargeant",
        fastestLap: "Lewis Hamilton",
      },
      "20": {
        pole: "Lando Norris",
        first: "Lewis Hamilton",
        last: "Kevin Magnussen",
        fastestLap: "Max Verstappen",
      },
      "21": {
        pole: "Max Verstappen",
        first: "Lewis Hamilton",
        last: "Logan Sargeant",
        fastestLap: "Lewis Hamilton",
      },
      "22": {
        pole: "Guanyu Zhou",
        first: "Logan Sargeant",
        last: "Max Verstappen",
        fastestLap: "Lance Stroll",
      },
      "23": {
        pole: "Lewis Hamilton",
        first: "Lewis Hamilton",
        last: "Max Verstappen",
        fastestLap: "Lewis Hamilton",
      },
    },
  },
  {
    id: 6,
    name: "Scott B",
    points: 0,
    predictions: {
      "1": {
        pole: "Charles Leclerc",
        first: "Max Verstappen",
        last: "Logan Sargeant",
        fastestLap: "Max Verstappen",
      },
      "2": {
        pole: "Max Verstappen",
        first: "Max Verstappen",
        last: "Nyck de Vries",
        fastestLap: "Fernando Alonso",
      },
      "3": {
        pole: "Max Verstappen",
        first: "Max Verstappen",
        last: "Logan Sargeant",
        fastestLap: "Max Verstappen",
      },
      "4": {
        pole: "Max Verstappen",
        first: "Max Verstappen",
        last: "Logan Sargeant",
        fastestLap: "Max Verstappen",
      },
      "5": {
        pole: "Max Verstappen",
        first: "Max Verstappen",
        last: "Nyck de Vries",
        fastestLap: "Max Verstappen",
      },
      "7": {
        pole: "Sergio Perez",
        first: "Sergio Perez",
        last: "Logan Sargeant",
        fastestLap: "Max Verstappen",
      },
      "8": {
        pole: "Max Verstappen",
        first: "Max Verstappen",
        last: "Nyck de Vries",
        fastestLap: "Max Verstappen",
      },
      "9": {
        pole: "Max Verstappen",
        first: "Max Verstappen",
        last: "Nico Hulkenberg",
        fastestLap: "Max Verstappen",
      },
      "10": {
        pole: "Max Verstappen",
        first: "Max Verstappen",
        last: "Logan Sargeant",
        fastestLap: "Lewis Hamilton",
      },
      "11": {
        pole: "Max Verstappen",
        first: "Max Verstappen",
        last: "Nyck de Vries",
        fastestLap: "Max Verstappen",
      },
      "12": {
        pole: "Max Verstappen",
        first: "Max Verstappen",
        last: "Kevin Magnussen",
        fastestLap: "Max Verstappen",
      },
      "13": {
        pole: "Lando Norris",
        first: "Max Verstappen",
        last: "Logan Sargeant",
        fastestLap: "Max Verstappen",
      },
      "14": {
        pole: "Max Verstappen",
        first: "Max Verstappen",
        last: "Logan Sargeant",
        fastestLap: "Max Verstappen",
      },
      "15": {
        pole: "Max Verstappen",
        first: "Max Verstappen",
        last: "Kevin Magnussen",
        fastestLap: "Max Verstappen",
      },
      "16": {
        pole: "Charles Leclerc",
        first: "Max Verstappen",
        last: "Kevin Magnussen",
        fastestLap: "Lewis Hamilton",
      },
      "17": {
        pole: "Max Verstappen",
        first: "Max Verstappen",
        last: "Valtteri Bottas",
        fastestLap: "Max Verstappen",
      },
      "18": {
        pole: "Lando Norris",
        first: "Max Verstappen",
        last: "Kevin Magnussen",
        fastestLap: "Max Verstappen",
      },
      "19": {
        pole: "Max Verstappen",
        first: "Max Verstappen",
        last: "Kevin Magnussen",
        fastestLap: "Max Verstappen",
      },
      "20": {
        pole: "Max Verstappen",
        first: "Max Verstappen",
        last: "Kevin Magnussen",
        fastestLap: "Max Verstappen",
      },
      "21": {
        pole: "Max Verstappen",
        first: "Max Verstappen",
        last: "Kevin Magnussen",
        fastestLap: "Max Verstappen",
      },
      "22": {
        pole: "Charles Leclerc",
        first: "Max Verstappen",
        last: "Kevin Magnussen",
        fastestLap: "Lando Norris",
      },
      "23": {
        pole: "Charles Leclerc",
        first: "Max Verstappen",
        last: "Logan Sargeant",
        fastestLap: "Lando Norris",
      },
    },
  },
  {
    id: 7,
    name: "Matt H",
    points: 0,
    predictions: {
      "1": {
        pole: "Charles Leclerc",
        first: "Charles Leclerc",
        last: "Lance Stroll",
        fastestLap: "Max Verstappen",
      },
      "2": {
        pole: "Max Verstappen",
        first: "Max Verstappen",
        last: "Oscar Piastri",
        fastestLap: "Charles Leclerc",
      },
      "3": {
        pole: "Max Verstappen",
        first: "Max Verstappen",
        last: "Oscar Piastri",
        fastestLap: "Sergio Perez",
      },
      "4": {
        pole: "Max Verstappen",
        first: "Max Verstappen",
        last: "Valtteri Bottas",
        fastestLap: "Sergio Perez",
      },
      "5": {
        pole: "Max Verstappen",
        first: "Sergio Perez",
        last: "Valtteri Bottas",
        fastestLap: "Sergio Perez",
      },
      "7": {
        pole: "Sergio Perez",
        first: "Sergio Perez",
        last: "Logan Sargeant",
        fastestLap: "Lewis Hamilton",
      },
      "8": {
        pole: "Max Verstappen",
        first: "Max Verstappen",
        last: "Valtteri Bottas",
        fastestLap: "Fernando Alonso",
      },
      "9": {
        pole: "Max Verstappen",
        first: "Max Verstappen",
        last: "Logan Sargeant",
        fastestLap: "Fernando Alonso",
      },
      "10": {
        pole: "Max Verstappen",
        first: "Max Verstappen",
        last: "Logan Sargeant",
        fastestLap: "Sergio Perez",
      },
      "11": {
        pole: "Max Verstappen",
        first: "Max Verstappen",
        last: "Logan Sargeant",
        fastestLap: "Max Verstappen",
      },
      "12": {
        pole: "Max Verstappen",
        first: "Lewis Hamilton",
        last: "Kevin Magnussen",
        fastestLap: "Max Verstappen",
      },
      "13": {
        pole: "Charles Leclerc",
        first: "Max Verstappen",
        last: "Logan Sargeant",
        fastestLap: "Max Verstappen",
      },
      "14": {
        pole: "Max Verstappen",
        first: "Max Verstappen",
        last: "Logan Sargeant",
        fastestLap: "Max Verstappen",
      },
      "15": {
        pole: "Max Verstappen",
        first: "Max Verstappen",
        last: "Logan Sargeant",
        fastestLap: "Max Verstappen",
      },
      "16": {
        pole: "Lewis Hamilton",
        first: "Lewis Hamilton",
        last: "Valtteri Bottas",
        fastestLap: "Max Verstappen",
      },
      "17": {
        pole: "Max Verstappen",
        first: "Max Verstappen",
        last: "Logan Sargeant",
        fastestLap: "Max Verstappen",
      },
      "18": {
        pole: "Max Verstappen",
        first: "Max Verstappen",
        last: "Logan Sargeant",
        fastestLap: "Max Verstappen",
      },
      "19": {
        pole: "Max Verstappen",
        first: "Lewis Hamilton",
        last: "Logan Sargeant",
        fastestLap: "Max Verstappen",
      },
      "20": {
        pole: "Max Verstappen",
        first: "Max Verstappen",
        last: "Logan Sargeant",
        fastestLap: "Max Verstappen",
      },
      "21": {
        pole: "Lewis Hamilton",
        first: "Lewis Hamilton",
        last: "Logan Sargeant",
        fastestLap: "Max Verstappen",
      },
      "22": {
        pole: "Charles Leclerc",
        first: "Max Verstappen",
        last: "Guanyu Zhou",
        fastestLap: "Lando Norris",
      },
      "23": {
        pole: "Charles Leclerc",
        first: "Lewis Hamilton",
        last: "Guanyu Zhou",
        fastestLap: "Max Verstappen",
      },
    },
  },
  {
    id: 8,
    name: "James C",
    points: 0,
    predictions: {
      "1": {
        pole: "Max Verstappen",
        first: "Max Verstappen",
        last: "Logan Sargeant",
        fastestLap: "Max Verstappen",
      },
      "2": {
        pole: "Max Verstappen",
        first: "Max Verstappen",
        last: "Oscar Piastri",
        fastestLap: "Max Verstappen",
      },
      "3": {
        pole: "Max Verstappen",
        first: "Max Verstappen",
        last: "Lando Norris",
        fastestLap: "Max Verstappen",
      },
      "4": {
        pole: "Max Verstappen",
        first: "Max Verstappen",
        last: "Logan Sargeant",
        fastestLap: "Max Verstappen",
      },
      "5": {
        pole: "Max Verstappen",
        first: "Max Verstappen",
        last: "Logan Sargeant",
        fastestLap: "Max Verstappen",
      },
      "7": {
        pole: "Max Verstappen",
        first: "Max Verstappen",
        last: "Logan Sargeant",
        fastestLap: "Max Verstappen",
      },
      "8": {
        pole: "Max Verstappen",
        first: "Max Verstappen",
        last: "Logan Sargeant",
        fastestLap: "Max Verstappen",
      },
      "9": {
        pole: "Max Verstappen",
        first: "Max Verstappen",
        last: "Logan Sargeant",
        fastestLap: "Max Verstappen",
      },
      "10": {
        pole: "Max Verstappen",
        first: "Max Verstappen",
        last: "Logan Sargeant",
        fastestLap: "Max Verstappen",
      },
      "11": {
        pole: "Max Verstappen",
        first: "Max Verstappen",
        last: "Logan Sargeant",
        fastestLap: "Max Verstappen",
      },
      "12": {
        pole: "Max Verstappen",
        first: "Max Verstappen",
        last: "Logan Sargeant",
        fastestLap: "Max Verstappen",
      },
      "13": {
        pole: "Lando Norris",
        first: "Max Verstappen",
        last: "Logan Sargeant",
        fastestLap: "Max Verstappen",
      },
      "14": {
        pole: "Max Verstappen",
        first: "Max Verstappen",
        last: "Logan Sargeant",
        fastestLap: "Max Verstappen",
      },
      "15": {
        pole: "Max Verstappen",
        first: "Max Verstappen",
        last: "Logan Sargeant",
        fastestLap: "Fernando Alonso",
      },
      "16": {
        pole: "Max Verstappen",
        first: "Max Verstappen",
        last: "Logan Sargeant",
        fastestLap: "Max Verstappen",
      },
      "17": {
        pole: "Max Verstappen",
        first: "Max Verstappen",
        last: "Logan Sargeant",
        fastestLap: "Max Verstappen",
      },
      "18": {
        pole: "Max Verstappen",
        first: "Max Verstappen",
        last: "Logan Sargeant",
        fastestLap: "Max Verstappen",
      },
      "19": {
        pole: "Max Verstappen",
        first: "Max Verstappen",
        last: "Logan Sargeant",
        fastestLap: "Max Verstappen",
      },
      "20": {
        pole: "Max Verstappen",
        first: "Max Verstappen",
        last: "Logan Sargeant",
        fastestLap: "Max Verstappen",
      },
      "21": {
        pole: "Max Verstappen",
        first: "Max Verstappen",
        last: "Logan Sargeant",
        fastestLap: "Max Verstappen",
      },
      "22": {
        pole: "Max Verstappen",
        first: "Max Verstappen",
        last: "Logan Sargeant",
        fastestLap: "Max Verstappen",
      },
      "23": {
        pole: "Max Verstappen",
        first: "Max Verstappen",
        last: "Logan Sargeant",
        fastestLap: "Max Verstappen",
      },
    },
  },
  {
    id: 9,
    name: "Harvey S",
    points: 0,
    predictions: {
      "1": {
        pole: "Max Verstappen",
        first: "Max Verstappen",
        last: "Oscar Piastri",
        fastestLap: "Fernando Alonso",
      },
      "2": {
        pole: "Max Verstappen",
        first: "Max Verstappen",
        last: "Logan Sargeant",
        fastestLap: "Max Verstappen",
      },
      "3": {
        pole: "Max Verstappen",
        first: "Max Verstappen",
        last: "Guanyu Zhou",
        fastestLap: "Fernando Alonso",
      },
      "4": {
        pole: "Max Verstappen",
        first: "Max Verstappen",
        last: "Oscar Piastri",
        fastestLap: "Sergio Perez",
      },
      "5": {
        pole: "Max Verstappen",
        first: "Max Verstappen",
        last: "Nyck de Vries",
        fastestLap: "Fernando Alonso",
      },
      "7": {
        pole: "Carlos Sainz",
        first: "Max Verstappen",
        last: "Oscar Piastri",
        fastestLap: "Max Verstappen",
      },
      "8": {
        pole: "Max Verstappen",
        first: "Max Verstappen",
        last: "Logan Sargeant",
        fastestLap: "Fernando Alonso",
      },
      "9": {
        pole: "Max Verstappen",
        first: "Max Verstappen",
        last: "Logan Sargeant",
        fastestLap: "George Russell",
      },
      "10": {
        pole: "Max Verstappen",
        first: "Max Verstappen",
        last: "Logan Sargeant",
        fastestLap: "Max Verstappen",
      },
      "11": {
        pole: "Max Verstappen",
        first: "Max Verstappen",
        last: "Logan Sargeant",
        fastestLap: "Max Verstappen",
      },
      "12": {
        pole: "Max Verstappen",
        first: "Max Verstappen",
        last: "Guanyu Zhou",
        fastestLap: "Sergio Perez",
      },
      "13": {
        pole: "Lando Norris",
        first: "Max Verstappen",
        last: "Logan Sargeant",
        fastestLap: "Max Verstappen",
      },
      "14": {
        pole: "Max Verstappen",
        first: "Max Verstappen",
        last: "Liam Lawson",
        fastestLap: "Max Verstappen",
      },
      "15": {
        pole: "Max Verstappen",
        first: "Max Verstappen",
        last: "Liam Lawson",
        fastestLap: "Max Verstappen",
      },
      "16": {
        pole: "Max Verstappen",
        first: "Max Verstappen",
        last: "Guanyu Zhou",
        fastestLap: "Lewis Hamilton",
      },
      "17": {
        pole: "Max Verstappen",
        first: "Max Verstappen",
        last: "Guanyu Zhou",
        fastestLap: "Max Verstappen",
      },
      "18": {
        pole: "Max Verstappen",
        first: "Max Verstappen",
        last: "Kevin Magnussen",
        fastestLap: "Max Verstappen",
      },
      "19": {
        pole: "Max Verstappen",
        first: "Max Verstappen",
        last: "Lance Stroll",
        fastestLap: "Max Verstappen",
      },
      "20": {
        pole: "Max Verstappen",
        first: "Max Verstappen",
        last: "Guanyu Zhou",
        fastestLap: "Max Verstappen",
      },
      "21": {
        pole: "Max Verstappen",
        first: "Max Verstappen",
        last: "Kevin Magnussen",
        fastestLap: "Max Verstappen",
      },
      "22": {
        pole: "Max Verstappen",
        first: "Max Verstappen",
        last: "Logan Sargeant",
        fastestLap: "Carlos Sainz",
      },
      "23": {
        pole: "Max Verstappen",
        first: "Max Verstappen",
        last: "Logan Sargeant",
        fastestLap: "Max Verstappen",
      },
    },
  },
  {
    id: 10,
    name: "Ollie S",
    points: 0,
    predictions: {
      "1": {
        pole: "Max Verstappen",
        first: "Max Verstappen",
        last: "Oscar Piastri",
        fastestLap: "Max Verstappen",
      },
      "2": {
        pole: "Max Verstappen",
        first: "Max Verstappen",
        last: "Guanyu Zhou",
        fastestLap: "Sergio Perez",
      },
      "3": {
        pole: "Lance Stroll",
        first: "Max Verstappen",
        last: "Oscar Piastri",
        fastestLap: "Nico Hulkenberg",
      },
      "4": {
        pole: "Sergio Perez",
        first: "Fernando Alonso",
        last: "Lewis Hamilton",
        fastestLap: "Max Verstappen",
      },
      "5": {
        pole: "Charles Leclerc",
        first: "Sergio Perez",
        last: "Nyck de Vries",
        fastestLap: "Sergio Perez",
      },
      "7": {
        pole: "Fernando Alonso",
        first: "Fernando Alonso",
        last: "Kevin Magnussen",
        fastestLap: "Carlos Sainz",
      },
      "8": {
        pole: "Fernando Alonso",
        first: "Fernando Alonso",
        last: "Kevin Magnussen",
        fastestLap: "Fernando Alonso",
      },
      "9": {
        pole: "Max Verstappen",
        first: "Max Verstappen",
        last: "Guanyu Zhou",
        fastestLap: "Sergio Perez",
      },
      "10": {
        pole: "Max Verstappen",
        first: "Max Verstappen",
        last: "Nyck de Vries",
        fastestLap: "Max Verstappen",
      },
      "11": {
        pole: "Carlos Sainz",
        first: "Max Verstappen",
        last: "Nyck de Vries",
        fastestLap: "Max Verstappen",
      },
      "12": {
        pole: "Max Verstappen",
        first: "Max Verstappen",
        last: "Daniel Ricciardo",
        fastestLap: "Max Verstappen",
      },
      "13": {
        pole: "Sergio Perez",
        first: "Max Verstappen",
        last: "Valtteri Bottas",
        fastestLap: "Max Verstappen",
      },
      "15": {
        pole: "Max Verstappen",
        first: "Max Verstappen",
        last: "Carlos Sainz",
        fastestLap: "Alex Albon",
      },
      "16": {
        pole: "Charles Leclerc",
        first: "Liam Lawson",
        last: "Kevin Magnussen",
        fastestLap: "Fernando Alonso",
      },
      "17": {
        pole: "Max Verstappen",
        first: "Max Verstappen",
        last: "Kevin Magnussen",
        fastestLap: "Max Verstappen",
      },
      "18": {
        pole: "Max Verstappen",
        first: "Max Verstappen",
        last: "Yuki Tsunoda",
        fastestLap: "Oscar Piastri",
      },
      "19": {
        pole: "Max Verstappen",
        first: "Max Verstappen",
        last: "Valtteri Bottas",
        fastestLap: "Max Verstappen",
      },
      "20": {
        pole: "Max Verstappen",
        first: "Max Verstappen",
        last: "Nico Hulkenberg",
        fastestLap: "Max Verstappen",
      },
      "21": {
        pole: "Lewis Hamilton",
        first: "Lewis Hamilton",
        last: "Yuki Tsunoda",
        fastestLap: "George Russell",
      },
      "22": {
        pole: "Charles Leclerc",
        first: "Max Verstappen",
        last: "Lewis Hamilton",
        fastestLap: "Yuki Tsunoda",
      },
      "23": {
        pole: "Charles Leclerc",
        first: "Max Verstappen",
        last: "Nico Hulkenberg",
        fastestLap: "Max Verstappen",
      },
    },
  },
  {
    id: 11,
    name: "James R",
    points: 0,
    predictions: {
      "1": {
        pole: "Max Verstappen",
        first: "Max Verstappen",
        last: "Nyck de Vries",
        fastestLap: "Fernando Alonso",
      },
      "2": {
        pole: "Max Verstappen",
        first: "Max Verstappen",
        last: "Oscar Piastri",
        fastestLap: "Fernando Alonso",
      },
      "3": {
        pole: "Fernando Alonso",
        first: "Max Verstappen",
        last: "Alex Albon",
        fastestLap: "Max Verstappen",
      },
      "4": {
        pole: "Sergio Perez",
        first: "Max Verstappen",
        last: "Esteban Ocon",
        fastestLap: "Max Verstappen",
      },
      "5": {
        pole: "Max Verstappen",
        first: "Max Verstappen",
        last: "Guanyu Zhou",
        fastestLap: "Charles Leclerc",
      },
      "7": {
        pole: "Sergio Perez",
        first: "Sergio Perez",
        last: "Guanyu Zhou",
        fastestLap: "Max Verstappen",
      },
      "8": {
        pole: "Max Verstappen",
        first: "Max Verstappen",
        last: "Logan Sargeant",
        fastestLap: "Fernando Alonso",
      },
      "9": {
        pole: "Max Verstappen",
        first: "Max Verstappen",
        last: "Logan Sargeant",
        fastestLap: "Max Verstappen",
      },
      "10": {
        pole: "Max Verstappen",
        first: "Max Verstappen",
        last: "Logan Sargeant",
        fastestLap: "Max Verstappen",
      },
      "11": {
        pole: "Max Verstappen",
        first: "Max Verstappen",
        last: "Nyck de Vries",
        fastestLap: "Max Verstappen",
      },
      "12": {
        pole: "Max Verstappen",
        first: "Max Verstappen",
        last: "Logan Sargeant",
        fastestLap: "Max Verstappen",
      },
      "13": {
        pole: "Carlos Sainz",
        first: "Max Verstappen",
        last: "Logan Sargeant",
        fastestLap: "Max Verstappen",
      },
      "14": {
        pole: "Max Verstappen",
        first: "Max Verstappen",
        last: "Logan Sargeant",
        fastestLap: "George Russell",
      },
      "15": {
        pole: "Max Verstappen",
        first: "Max Verstappen",
        last: "Kevin Magnussen",
        fastestLap: "Charles Leclerc",
      },
      "16": {
        pole: "Charles Leclerc",
        first: "Max Verstappen",
        last: "Kevin Magnussen",
        fastestLap: "Max Verstappen",
      },
      "17": {
        pole: "Max Verstappen",
        first: "Max Verstappen",
        last: "Kevin Magnussen",
        fastestLap: "Carlos Sainz",
      },
      "18": {
        pole: "Max Verstappen",
        first: "Max Verstappen",
        last: "Kevin Magnussen",
        fastestLap: "Carlos Sainz",
      },
      "19": {
        pole: "Max Verstappen",
        first: "Max Verstappen",
        last: "Kevin Magnussen",
        fastestLap: "Max Verstappen",
      },
      "20": {
        pole: "Max Verstappen",
        first: "Max Verstappen",
        last: "Kevin Magnussen",
        fastestLap: "Max Verstappen",
      },
      "21": {
        pole: "Max Verstappen",
        first: "Lewis Hamilton",
        last: "Logan Sargeant",
        fastestLap: "George Russell",
      },
      "22": {
        pole: "Charles Leclerc",
        first: "Max Verstappen",
        last: "Logan Sargeant",
        fastestLap: "Max Verstappen",
      },
      "23": {
        pole: "Charles Leclerc",
        first: "Max Verstappen",
        last: "Nico Hulkenberg",
        fastestLap: "Max Verstappen",
      },
    },
  },
  {
    id: 12,
    name: "Sam C",
    points: 0,
    predictions: {
      "1": {
        pole: "Max Verstappen",
        first: "Max Verstappen",
        last: "Oscar Piastri",
        fastestLap: "Max Verstappen",
      },
      "2": {
        pole: "Max Verstappen",
        first: "Max Verstappen",
        last: "Nyck de Vries",
        fastestLap: "Max Verstappen",
      },
      "3": {
        pole: "Max Verstappen",
        first: "Max Verstappen",
        last: "Logan Sargeant",
        fastestLap: "Fernando Alonso",
      },
      "4": {
        pole: "Max Verstappen",
        first: "Max Verstappen",
        last: "Nyck de Vries",
        fastestLap: "Max Verstappen",
      },
      "5": {
        pole: "Charles Leclerc",
        first: "Max Verstappen",
        last: "Nyck de Vries",
        fastestLap: "Max Verstappen",
      },
      "7": {
        pole: "Charles Leclerc",
        first: "Max Verstappen",
        last: "Nyck de Vries",
        fastestLap: "Max Verstappen",
      },
      "8": {
        pole: "Max Verstappen",
        first: "Max Verstappen",
        last: "Logan Sargeant",
        fastestLap: "Max Verstappen",
      },
      "9": {
        pole: "Max Verstappen",
        first: "Max Verstappen",
        last: "Logan Sargeant",
        fastestLap: "Charles Leclerc",
      },
      "10": {
        pole: "Max Verstappen",
        first: "Max Verstappen",
        last: "Logan Sargeant",
        fastestLap: "Lewis Hamilton",
      },
      "11": {
        pole: "Max Verstappen",
        first: "Max Verstappen",
        last: "Nyck de Vries",
        fastestLap: "Sergio Perez",
      },
      "12": {
        pole: "Max Verstappen",
        first: "Max Verstappen",
        last: "Yuki Tsunoda",
        fastestLap: "Max Verstappen",
      },
      "13": {
        pole: "Sergio Perez",
        first: "Max Verstappen",
        last: "Logan Sargeant",
        fastestLap: "Max Verstappen",
      },
      "14": {
        pole: "Max Verstappen",
        first: "Max Verstappen",
        last: "Nico Hulkenberg",
        fastestLap: "Lando Norris",
      },
      "15": {
        pole: "Max Verstappen",
        first: "Max Verstappen",
        last: "Liam Lawson",
        fastestLap: "Lando Norris",
      },
      "16": {
        pole: "Carlos Sainz",
        first: "Max Verstappen",
        last: "Logan Sargeant",
        fastestLap: "Max Verstappen",
      },
      "17": {
        pole: "Max Verstappen",
        first: "Max Verstappen",
        last: "Logan Sargeant",
        fastestLap: "Max Verstappen",
      },
      "18": {
        pole: "Max Verstappen",
        first: "Max Verstappen",
        last: "Kevin Magnussen",
        fastestLap: "Max Verstappen",
      },
      "19": {
        pole: "Max Verstappen",
        first: "Max Verstappen",
        last: "Guanyu Zhou",
        fastestLap: "Max Verstappen",
      },
      "20": {
        pole: "Max Verstappen",
        first: "Max Verstappen",
        last: "Logan Sargeant",
        fastestLap: "Sergio Perez",
      },
      "21": {
        pole: "Max Verstappen",
        first: "Max Verstappen",
        last: "Logan Sargeant",
        fastestLap: "Lewis Hamilton",
      },
      "22": {
        pole: "Max Verstappen",
        first: "Max Verstappen",
        last: "Logan Sargeant",
        fastestLap: "Lando Norris",
      },
      "23": {
        pole: "Max Verstappen",
        first: "Max Verstappen",
        last: "Logan Sargeant",
        fastestLap: "Lando Norris",
      },
    },
  },
  {
    id: 13,
    name: "Lewis C",
    points: 0,
    predictions: {
      "1": {
        pole: "Charles Leclerc",
        first: "Max Verstappen",
        last: "Logan Sargeant",
        fastestLap: "Sergio Perez",
      },
      "2": {
        pole: "Max Verstappen",
        first: "Max Verstappen",
        last: "Nico Hulkenberg",
        fastestLap: "Charles Leclerc",
      },
      "3": {
        pole: "Max Verstappen",
        first: "Max Verstappen",
        last: "Guanyu Zhou",
        fastestLap: "Sergio Perez",
      },
      "4": {
        pole: "Max Verstappen",
        first: "Max Verstappen",
        last: "Nico Hulkenberg",
        fastestLap: "Charles Leclerc",
      },
      "5": {
        pole: "Max Verstappen",
        first: "Max Verstappen",
        last: "Guanyu Zhou",
        fastestLap: "Sergio Perez",
      },
      "7": {
        pole: "Fernando Alonso",
        first: "Fernando Alonso",
        last: "Nyck de Vries",
        fastestLap: "Sergio Perez",
      },
      "8": {
        pole: "Max Verstappen",
        first: "Max Verstappen",
        last: "Valtteri Bottas",
        fastestLap: "Sergio Perez",
      },
      "9": {
        pole: "Fernando Alonso",
        first: "Max Verstappen",
        last: "Valtteri Bottas",
        fastestLap: "Lewis Hamilton",
      },
      "10": {
        pole: "Max Verstappen",
        first: "Max Verstappen",
        last: "Nyck de Vries",
        fastestLap: "Fernando Alonso",
      },
      "11": {
        pole: "Max Verstappen",
        first: "Max Verstappen",
        last: "Nyck de Vries",
        fastestLap: "Fernando Alonso",
      },
      "12": {
        pole: "Max Verstappen",
        first: "Max Verstappen",
        last: "Valtteri Bottas",
        fastestLap: "Max Verstappen",
      },
      "13": {
        pole: "George Russell",
        first: "Max Verstappen",
        last: "Kevin Magnussen",
        fastestLap: "Max Verstappen",
      },
      "14": {
        pole: "Max Verstappen",
        first: "Max Verstappen",
        last: "Logan Sargeant",
        fastestLap: "Max Verstappen",
      },
      "15": {
        pole: "Max Verstappen",
        first: "Max Verstappen",
        last: "Valtteri Bottas",
        fastestLap: "Lando Norris",
      },
      "16": {
        pole: "Max Verstappen",
        first: "Lando Norris",
        last: "Valtteri Bottas",
        fastestLap: "Lando Norris",
      },
      "17": {
        pole: "Max Verstappen",
        first: "Max Verstappen",
        last: "Valtteri Bottas",
        fastestLap: "Lewis Hamilton",
      },
      "18": {
        pole: "Max Verstappen",
        first: "Max Verstappen",
        last: "Valtteri Bottas",
        fastestLap: "Sergio Perez",
      },
      "19": {
        pole: "Max Verstappen",
        first: "Max Verstappen",
        last: "Nico Hulkenberg",
        fastestLap: "Carlos Sainz",
      },
      "20": {
        pole: "Max Verstappen",
        first: "Max Verstappen",
        last: "Nico Hulkenberg",
        fastestLap: "Lewis Hamilton",
      },
      "21": {
        pole: "Charles Leclerc",
        first: "Lewis Hamilton",
        last: "Valtteri Bottas",
        fastestLap: "Lewis Hamilton",
      },
      "22": {
        pole: "Carlos Sainz",
        first: "Max Verstappen",
        last: "Nico Hulkenberg",
        fastestLap: "Max Verstappen",
      },
      "23": {
        pole: "Carlos Sainz",
        first: "Max Verstappen",
        last: "Nico Hulkenberg",
        fastestLap: "Max Verstappen",
      },
    },
  },
  {
    id: 14,
    name: "Gemma R",
    points: 0,
    predictions: {
      "1": {
        pole: "Max Verstappen",
        first: "Max Verstappen",
        last: "Logan Sargeant",
        fastestLap: "Sergio Perez",
      },
      "2": {
        pole: "Max Verstappen",
        first: "Max Verstappen",
        last: "Nico Hulkenberg",
        fastestLap: "Sergio Perez",
      },
      "3": {
        pole: "Max Verstappen",
        first: "Max Verstappen",
        last: "Logan Sargeant",
        fastestLap: "Sergio Perez",
      },
      "4": {
        pole: "Max Verstappen",
        first: "Max Verstappen",
        last: "Nico Hulkenberg",
        fastestLap: "Sergio Perez",
      },
      "5": {
        pole: "Max Verstappen",
        first: "Max Verstappen",
        last: "Nico Hulkenberg",
        fastestLap: "Sergio Perez",
      },
      "7": {
        pole: "Max Verstappen",
        first: "Max Verstappen",
        last: "Logan Sargeant",
        fastestLap: "Charles Leclerc",
      },
      "8": {
        pole: "Max Verstappen",
        first: "Max Verstappen",
        last: "Logan Sargeant",
        fastestLap: "Fernando Alonso",
      },
      "9": {
        pole: "Max Verstappen",
        first: "Max Verstappen",
        last: "Logan Sargeant",
        fastestLap: "Sergio Perez",
      },
      "10": {
        pole: "Max Verstappen",
        first: "Max Verstappen",
        last: "Logan Sargeant",
        fastestLap: "Sergio Perez",
      },
      "11": {
        pole: "Max Verstappen",
        first: "Max Verstappen",
        last: "Kevin Magnussen",
        fastestLap: "Sergio Perez",
      },
      "12": {
        pole: "Max Verstappen",
        first: "Max Verstappen",
        last: "Logan Sargeant",
        fastestLap: "Sergio Perez",
      },
      "13": {
        pole: "Lando Norris",
        first: "Max Verstappen",
        last: "Kevin Magnussen",
        fastestLap: "Sergio Perez",
      },
      "14": {
        pole: "Max Verstappen",
        first: "Max Verstappen",
        last: "Nico Hulkenberg",
        fastestLap: "Sergio Perez",
      },
      "15": {
        pole: "Max Verstappen",
        first: "Max Verstappen",
        last: "Nico Hulkenberg",
        fastestLap: "Sergio Perez",
      },
      "16": {
        pole: "Max Verstappen",
        first: "Max Verstappen",
        last: "Logan Sargeant",
        fastestLap: "Sergio Perez",
      },
      "17": {
        pole: "Max Verstappen",
        first: "Max Verstappen",
        last: "Kevin Magnussen",
        fastestLap: "Sergio Perez",
      },
      "18": {
        pole: "Carlos Sainz",
        first: "Max Verstappen",
        last: "Nico Hulkenberg",
        fastestLap: "Sergio Perez",
      },
      "19": {
        pole: "Max Verstappen",
        first: "Max Verstappen",
        last: "Nico Hulkenberg",
        fastestLap: "Sergio Perez",
      },
      "20": {
        pole: "Max Verstappen",
        first: "Max Verstappen",
        last: "Logan Sargeant",
        fastestLap: "Max Verstappen",
      },
      "21": {
        pole: "Max Verstappen",
        first: "Max Verstappen",
        last: "Logan Sargeant",
        fastestLap: "Max Verstappen",
      },
      "22": {
        pole: "Fernando Alonso",
        first: "Max Verstappen",
        last: "Logan Sargeant",
        fastestLap: "Sergio Perez",
      },
      "23": {
        pole: "Max Verstappen",
        first: "Max Verstappen",
        last: "Nico Hulkenberg",
        fastestLap: "Sergio Perez",
      },
    },
  },
  {
    id: 15,
    name: "Owen M",
    points: 0,
    predictions: {
      "1": {
        pole: "Max Verstappen",
        first: "Max Verstappen",
        last: "Alex Albon",
        fastestLap: "Sergio Perez",
      },
      "2": {
        pole: "Max Verstappen",
        first: "Max Verstappen",
        last: "Oscar Piastri",
        fastestLap: "Max Verstappen",
      },
      "3": {
        pole: "Max Verstappen",
        first: "Max Verstappen",
        last: "Oscar Piastri",
        fastestLap: "Max Verstappen",
      },
      "4": {
        pole: "Max Verstappen",
        first: "Max Verstappen",
        last: "Nico Hulkenberg",
        fastestLap: "Max Verstappen",
      },
      "5": {
        pole: "Max Verstappen",
        first: "Max Verstappen",
        last: "Nico Hulkenberg",
        fastestLap: "Sergio Perez",
      },
      "7": {
        pole: "Max Verstappen",
        first: "Max Verstappen",
        last: "Nyck de Vries",
        fastestLap: "Max Verstappen",
      },
      "8": {
        pole: "Max Verstappen",
        first: "Max Verstappen",
        last: "Nico Hulkenberg",
        fastestLap: "Max Verstappen",
      },
      "9": {
        pole: "Max Verstappen",
        first: "Max Verstappen",
        last: "Nico Hulkenberg",
        fastestLap: "Max Verstappen",
      },
      "10": {
        pole: "Max Verstappen",
        first: "Max Verstappen",
        last: "Logan Sargeant",
        fastestLap: "Max Verstappen",
      },
      "11": {
        pole: "Max Verstappen",
        first: "Max Verstappen",
        last: "Nyck de Vries",
        fastestLap: "Max Verstappen",
      },
      "12": {
        pole: "Max Verstappen",
        first: "Max Verstappen",
        last: "Logan Sargeant",
        fastestLap: "Max Verstappen",
      },
      "13": {
        pole: "Lando Norris",
        first: "Max Verstappen",
        last: "Logan Sargeant",
        fastestLap: "Max Verstappen",
      },
      "14": {
        pole: "Max Verstappen",
        first: "Max Verstappen",
        last: "Logan Sargeant",
        fastestLap: "Max Verstappen",
      },
      "15": {
        pole: "Max Verstappen",
        first: "Max Verstappen",
        last: "Logan Sargeant",
        fastestLap: "Max Verstappen",
      },
      "16": {
        pole: "Max Verstappen",
        first: "Max Verstappen",
        last: "Logan Sargeant",
        fastestLap: "Max Verstappen",
      },
      "17": {
        pole: "Max Verstappen",
        first: "Max Verstappen",
        last: "Logan Sargeant",
        fastestLap: "Lando Norris",
      },
      "18": {
        pole: "Max Verstappen",
        first: "Max Verstappen",
        last: "Logan Sargeant",
        fastestLap: "Lando Norris",
      },
      "19": {
        pole: "Max Verstappen",
        first: "Max Verstappen",
        last: "Kevin Magnussen",
        fastestLap: "Max Verstappen",
      },
      "20": {
        pole: "Max Verstappen",
        first: "Max Verstappen",
        last: "Logan Sargeant",
        fastestLap: "Max Verstappen",
      },
      "21": {
        pole: "Max Verstappen",
        first: "Max Verstappen",
        last: "Kevin Magnussen",
        fastestLap: "Max Verstappen",
      },
      "22": {
        pole: "Max Verstappen",
        first: "Max Verstappen",
        last: "Kevin Magnussen",
        fastestLap: "Max Verstappen",
      },
      "23": {
        pole: "Max Verstappen",
        first: "Max Verstappen",
        last: "Kevin Magnussen",
        fastestLap: "Max Verstappen",
      },
    },
  },
  {
    id: 16,
    name: "Cam B",
    points: 0,
    predictions: {
      "1": {
        pole: "Max Verstappen",
        first: "Max Verstappen",
        last: "Logan Sargeant",
        fastestLap: "Sergio Perez",
      },
      "2": {
        pole: "Max Verstappen",
        first: "Max Verstappen",
        last: "Nico Hulkenberg",
        fastestLap: "Sergio Perez",
      },
      "3": {
        pole: "Max Verstappen",
        first: "Max Verstappen",
        last: "Nyck de Vries",
        fastestLap: "Fernando Alonso",
      },
      "4": {
        pole: "Fernando Alonso",
        first: "Max Verstappen",
        last: "Guanyu Zhou",
        fastestLap: "Sergio Perez",
      },
      "5": {
        pole: "Charles Leclerc",
        first: "Max Verstappen",
        last: "Guanyu Zhou",
        fastestLap: "George Russell",
      },
      "7": {
        pole: "Sergio Perez",
        first: "Sergio Perez",
        last: "Nico Hulkenberg",
        fastestLap: "Fernando Alonso",
      },
      "8": {
        pole: "Max Verstappen",
        first: "Max Verstappen",
        last: "Guanyu Zhou",
        fastestLap: "Sergio Perez",
      },
      "9": {
        pole: "Max Verstappen",
        first: "Max Verstappen",
        last: "Nico Hulkenberg",
        fastestLap: "Lewis Hamilton",
      },
      "10": {
        pole: "Max Verstappen",
        first: "Max Verstappen",
        last: "Logan Sargeant",
        fastestLap: "Sergio Perez",
      },
      "11": {
        pole: "Max Verstappen",
        first: "Max Verstappen",
        last: "Valtteri Bottas",
        fastestLap: "Max Verstappen",
      },
      "12": {
        pole: "Max Verstappen",
        first: "Max Verstappen",
        last: "Daniel Ricciardo",
        fastestLap: "Sergio Perez",
      },
      "13": {
        pole: "Lando Norris",
        first: "Max Verstappen",
        last: "Kevin Magnussen",
        fastestLap: "Max Verstappen",
      },
      "14": {
        pole: "Max Verstappen",
        first: "Max Verstappen",
        last: "Kevin Magnussen",
        fastestLap: "Max Verstappen",
      },
      "15": {
        pole: "Max Verstappen",
        first: "Max Verstappen",
        last: "Nico Hulkenberg",
        fastestLap: "Max Verstappen",
      },
      "16": {
        pole: "Lando Norris",
        first: "Max Verstappen",
        last: "Guanyu Zhou",
        fastestLap: "Lando Norris",
      },
      "17": {
        pole: "Max Verstappen",
        first: "Max Verstappen",
        last: "Guanyu Zhou",
        fastestLap: "Carlos Sainz",
      },
      "18": {
        pole: "Max Verstappen",
        first: "Max Verstappen",
        last: "Nico Hulkenberg",
        fastestLap: "Max Verstappen",
      },
      "19": {
        pole: "Max Verstappen",
        first: "Max Verstappen",
        last: "Guanyu Zhou",
        fastestLap: "Oscar Piastri",
      },
      "20": {
        pole: "Max Verstappen",
        first: "Max Verstappen",
        last: "Kevin Magnussen",
        fastestLap: "Lewis Hamilton",
      },
      "21": {
        pole: "Max Verstappen",
        first: "Max Verstappen",
        last: "Guanyu Zhou",
        fastestLap: "Lando Norris",
      },
      "22": {
        pole: "Max Verstappen",
        first: "Max Verstappen",
        last: "Guanyu Zhou",
        fastestLap: "Lando Norris",
      },
      "23": {
        pole: "Max Verstappen",
        first: "Max Verstappen",
        last: "Guanyu Zhou",
        fastestLap: "Sergio Perez",
      },
    },
  },
  {
    id: 17,
    name: "Callum A",
    points: 0,
    predictions: {
      "1": {
        pole: "Max Verstappen",
        first: "Max Verstappen",
        last: "Oscar Piastri",
        fastestLap: "Sergio Perez",
      },
      "2": {
        pole: "Max Verstappen",
        first: "Max Verstappen",
        last: "Oscar Piastri",
        fastestLap: "Charles Leclerc",
      },
      "3": {
        pole: "Max Verstappen",
        first: "Max Verstappen",
        last: "Kevin Magnussen",
        fastestLap: "Sergio Perez",
      },
      "4": {
        pole: "Max Verstappen",
        first: "Max Verstappen",
        last: "Nico Hulkenberg",
        fastestLap: "Sergio Perez",
      },
      "5": {
        pole: "Charles Leclerc",
        first: "Max Verstappen",
        last: "Logan Sargeant",
        fastestLap: "Charles Leclerc",
      },
      "7": {
        pole: "Charles Leclerc",
        first: "Max Verstappen",
        last: "Logan Sargeant",
        fastestLap: "George Russell",
      },
      "8": {
        pole: "Max Verstappen",
        first: "Max Verstappen",
        last: "Logan Sargeant",
        fastestLap: "Sergio Perez",
      },
      "9": {
        pole: "Max Verstappen",
        first: "Max Verstappen",
        last: "Logan Sargeant",
        fastestLap: "Max Verstappen",
      },
      "10": {
        pole: "Max Verstappen",
        first: "Max Verstappen",
        last: "Logan Sargeant",
        fastestLap: "Max Verstappen",
      },
      "11": {
        pole: "Max Verstappen",
        first: "Max Verstappen",
        last: "Kevin Magnussen",
        fastestLap: "Charles Leclerc",
      },
      "12": {
        pole: "Max Verstappen",
        first: "Max Verstappen",
        last: "Kevin Magnussen",
        fastestLap: "Max Verstappen",
      },
      "13": {
        pole: "Sergio Perez",
        first: "Max Verstappen",
        last: "Logan Sargeant",
        fastestLap: "Max Verstappen",
      },
      "14": {
        pole: "Max Verstappen",
        first: "Max Verstappen",
        last: "Liam Lawson",
        fastestLap: "Max Verstappen",
      },
      "15": {
        pole: "Max Verstappen",
        first: "Max Verstappen",
        last: "Guanyu Zhou",
        fastestLap: "Lando Norris",
      },
      "16": {
        pole: "Charles Leclerc",
        first: "Max Verstappen",
        last: "Nico Hulkenberg",
        fastestLap: "Carlos Sainz",
      },
      "17": {
        pole: "Max Verstappen",
        first: "Max Verstappen",
        last: "Logan Sargeant",
        fastestLap: "Max Verstappen",
      },
      "18": {
        pole: "Max Verstappen",
        first: "Max Verstappen",
        last: "Kevin Magnussen",
        fastestLap: "Max Verstappen",
      },
      "19": {
        pole: "Max Verstappen",
        first: "Max Verstappen",
        last: "Logan Sargeant",
        fastestLap: "Max Verstappen",
      },
      "20": {
        pole: "Max Verstappen",
        first: "Max Verstappen",
        last: "Kevin Magnussen",
        fastestLap: "Max Verstappen",
      },
      "21": {
        pole: "Charles Leclerc",
        first: "George Russell",
        last: "Kevin Magnussen",
        fastestLap: "Max Verstappen",
      },
      "22": {
        pole: "Charles Leclerc",
        first: "George Russell",
        last: "Kevin Magnussen",
        fastestLap: "Max Verstappen",
      },
      "23": {
        pole: "Max Verstappen",
        first: "Max Verstappen",
        last: "Kevin Magnussen",
        fastestLap: "Max Verstappen",
      },
    },
  },
  {
    id: 18,
    name: "Lewis B",
    points: 0,
    predictions: {
      "1": {
        pole: "Max Verstappen",
        first: "Max Verstappen",
        last: "Logan Sargeant",
        fastestLap: "Fernando Alonso",
      },
      "2": {
        pole: "Max Verstappen",
        first: "Max Verstappen",
        last: "Oscar Piastri",
        fastestLap: "Sergio Perez",
      },
      "3": {
        pole: "Max Verstappen",
        first: "Max Verstappen",
        last: "Nico Hulkenberg",
        fastestLap: "Charles Leclerc",
      },
      "4": {
        pole: "Max Verstappen",
        first: "Max Verstappen",
        last: "Logan Sargeant",
        fastestLap: "George Russell",
      },
      "5": {
        pole: "Max Verstappen",
        first: "Max Verstappen",
        last: "Nico Hulkenberg",
        fastestLap: "Lewis Hamilton",
      },
      "7": {
        pole: "Charles Leclerc",
        first: "Sergio Perez",
        last: "Oscar Piastri",
        fastestLap: "Sergio Perez",
      },
      "8": {
        pole: "Max Verstappen",
        first: "Max Verstappen",
        last: "Logan Sargeant",
        fastestLap: "Fernando Alonso",
      },
      "9": {
        pole: "Max Verstappen",
        first: "Max Verstappen",
        last: "Logan Sargeant",
        fastestLap: "Fernando Alonso",
      },
      "10": {
        pole: "Max Verstappen",
        first: "Max Verstappen",
        last: "Nyck de Vries",
        fastestLap: "Max Verstappen",
      },
      "11": {
        pole: "Max Verstappen",
        first: "Lewis Hamilton",
        last: "Oscar Piastri",
        fastestLap: "Max Verstappen",
      },
      "12": {
        pole: "Lando Norris",
        first: "Max Verstappen",
        last: "Nico Hulkenberg",
        fastestLap: "Max Verstappen",
      },
      "13": {
        pole: "Lando Norris",
        first: "Max Verstappen",
        last: "Logan Sargeant",
        fastestLap: "Max Verstappen",
      },
      "14": {
        pole: "Max Verstappen",
        first: "Max Verstappen",
        last: "Logan Sargeant",
        fastestLap: "Max Verstappen",
      },
      "15": {
        pole: "Max Verstappen",
        first: "Max Verstappen",
        last: "Charles Leclerc",
        fastestLap: "Max Verstappen",
      },
      "16": {
        pole: "Max Verstappen",
        first: "Max Verstappen",
        last: "Kevin Magnussen",
        fastestLap: "Max Verstappen",
      },
      "17": {
        pole: "Charles Leclerc",
        first: "Max Verstappen",
        last: "Guanyu Zhou",
        fastestLap: "Lewis Hamilton",
      },
      "18": {
        pole: "Max Verstappen",
        first: "Lewis Hamilton",
        last: "Guanyu Zhou",
        fastestLap: "Lewis Hamilton",
      },
      "19": {
        pole: "Max Verstappen",
        first: "Lewis Hamilton",
        last: "Nico Hulkenberg",
        fastestLap: "Charles Leclerc",
      },
      "20": {
        pole: "Max Verstappen",
        first: "Max Verstappen",
        last: "Logan Sargeant",
        fastestLap: "Max Verstappen",
      },
      "21": {
        pole: "Carlos Sainz",
        first: "Max Verstappen",
        last: "Logan Sargeant",
        fastestLap: "George Russell",
      },
      "22": {
        pole: "Carlos Sainz",
        first: "Max Verstappen",
        last: "Logan Sargeant",
        fastestLap: "George Russell",
      },
      "23": {
        pole: "Max Verstappen",
        first: "Max Verstappen",
        last: "Daniel Ricciardo",
        fastestLap: "Max Verstappen",
      },
    },
  },
  {
    id: 19,
    name: "Frank D",
    points: 0,
    predictions: {
      "1": {
        pole: "Max Verstappen",
        first: "Max Verstappen",
        last: "Guanyu Zhou",
        fastestLap: "Carlos Sainz",
      },
      "2": {
        pole: "Max Verstappen",
        first: "Max Verstappen",
        last: "Nyck de Vries",
        fastestLap: "Fernando Alonso",
      },
      "3": {
        pole: "Max Verstappen",
        first: "Max Verstappen",
        last: "Nico Hulkenberg",
        fastestLap: "Sergio Perez",
      },
      "4": {
        pole: "Max Verstappen",
        first: "Max Verstappen",
        last: "Nyck de Vries",
        fastestLap: "George Russell",
      },
      "5": {
        pole: "Max Verstappen",
        first: "Max Verstappen",
        last: "Guanyu Zhou",
        fastestLap: "Lewis Hamilton",
      },
      "7": {
        pole: "Max Verstappen",
        first: "Max Verstappen",
        last: "Logan Sargeant",
        fastestLap: "Sergio Perez",
      },
      "8": {
        pole: "Max Verstappen",
        first: "Max Verstappen",
        last: "Nyck de Vries",
        fastestLap: "Fernando Alonso",
      },
      "9": {
        pole: "Max Verstappen",
        first: "Max Verstappen",
        last: "Nyck de Vries",
        fastestLap: "Fernando Alonso",
      },
      "10": {
        pole: "Max Verstappen",
        first: "Max Verstappen",
        last: "Nyck de Vries",
        fastestLap: "Lewis Hamilton",
      },
      "11": {
        pole: "Max Verstappen",
        first: "Max Verstappen",
        last: "Nyck de Vries",
        fastestLap: "Max Verstappen",
      },
      "12": {
        pole: "Max Verstappen",
        first: "Max Verstappen",
        last: "Guanyu Zhou",
        fastestLap: "Max Verstappen",
      },
      "13": {
        pole: "Lando Norris",
        first: "Max Verstappen",
        last: "Logan Sargeant",
        fastestLap: "Max Verstappen",
      },
      "14": {
        pole: "Max Verstappen",
        first: "Max Verstappen",
        last: "Logan Sargeant",
        fastestLap: "Max Verstappen",
      },
      "15": {
        pole: "Max Verstappen",
        first: "Max Verstappen",
        last: "Logan Sargeant",
        fastestLap: "Max Verstappen",
      },
      "16": {
        pole: "Max Verstappen",
        first: "Max Verstappen",
        last: "Kevin Magnussen",
        fastestLap: "Max Verstappen",
      },
      "17": {
        pole: "Max Verstappen",
        first: "Max Verstappen",
        last: "Kevin Magnussen",
        fastestLap: "Max Verstappen",
      },
      "18": {
        pole: "Max Verstappen",
        first: "Max Verstappen",
        last: "Logan Sargeant",
        fastestLap: "Max Verstappen",
      },
      "19": {
        pole: "Max Verstappen",
        first: "Max Verstappen",
        last: "Logan Sargeant",
        fastestLap: "Max Verstappen",
      },
      "20": {
        pole: "Max Verstappen",
        first: "Max Verstappen",
        last: "Logan Sargeant",
        fastestLap: "Max Verstappen",
      },
      "21": {
        pole: "Max Verstappen",
        first: "Max Verstappen",
        last: "Kevin Magnussen",
        fastestLap: "Max Verstappen",
      },
      "22": {
        pole: "Max Verstappen",
        first: "Max Verstappen",
        last: "Logan Sargeant",
        fastestLap: "Charles Leclerc",
      },
      "23": {
        pole: "Max Verstappen",
        first: "Max Verstappen",
        last: "Logan Sargeant",
        fastestLap: "Lewis Hamilton",
      },
    },
  },
  {
    id: 20,
    name: "Angela R",
    points: 0,
    predictions: {
      "1": {
        pole: "Max Verstappen",
        first: "Max Verstappen",
        last: "Kevin Magnussen",
        fastestLap: "George Russell",
      },
      "2": {
        pole: "Max Verstappen",
        first: "Max Verstappen",
        last: "Nyck de Vries",
        fastestLap: "Sergio Perez",
      },
      "3": {
        pole: "Sergio Perez",
        first: "Sergio Perez",
        last: "Nyck de Vries",
        fastestLap: "Charles Leclerc",
      },
      "4": {
        pole: "Lewis Hamilton",
        first: "Lewis Hamilton",
        last: "Logan Sargeant",
        fastestLap: "Fernando Alonso",
      },
      "5": {
        pole: "Sergio Perez",
        first: "Max Verstappen",
        last: "Kevin Magnussen",
        fastestLap: "Charles Leclerc",
      },
      "7": {
        pole: "Sergio Perez",
        first: "Max Verstappen",
        last: "Nyck de Vries",
        fastestLap: "Fernando Alonso",
      },
      "8": {
        pole: "Max Verstappen",
        first: "Max Verstappen",
        last: "Logan Sargeant",
        fastestLap: "Max Verstappen",
      },
      "9": {
        pole: "Max Verstappen",
        first: "Max Verstappen",
        last: "Kevin Magnussen",
        fastestLap: "Max Verstappen",
      },
      "10": {
        pole: "Max Verstappen",
        first: "Max Verstappen",
        last: "Kevin Magnussen",
        fastestLap: "Sergio Perez",
      },
      "11": {
        pole: "Max Verstappen",
        first: "Max Verstappen",
        last: "Guanyu Zhou",
        fastestLap: "Charles Leclerc",
      },
      "12": {
        pole: "Max Verstappen",
        first: "Max Verstappen",
        last: "Kevin Magnussen",
        fastestLap: "Charles Leclerc",
      },
      "13": {
        pole: "Lando Norris",
        first: "Max Verstappen",
        last: "Kevin Magnussen",
        fastestLap: "Max Verstappen",
      },
      "14": {
        pole: "Max Verstappen",
        first: "Max Verstappen",
        last: "Logan Sargeant",
        fastestLap: "Lewis Hamilton",
      },
      "15": {
        pole: "Max Verstappen",
        first: "Max Verstappen",
        last: "Kevin Magnussen",
        fastestLap: "Sergio Perez",
      },
      "16": {
        pole: "Lewis Hamilton",
        first: "Max Verstappen",
        last: "Guanyu Zhou",
        fastestLap: "Max Verstappen",
      },
      "17": {
        pole: "Lando Norris",
        first: "Max Verstappen",
        last: "Kevin Magnussen",
        fastestLap: "Max Verstappen",
      },
      "18": {
        pole: "Lando Norris",
        first: "Max Verstappen",
        last: "Valtteri Bottas",
        fastestLap: "Lewis Hamilton",
      },
      "19": {
        pole: "Max Verstappen",
        first: "Max Verstappen",
        last: "Kevin Magnussen",
        fastestLap: "Lando Norris",
      },
      "20": {
        pole: "Max Verstappen",
        first: "Max Verstappen",
        last: "Guanyu Zhou",
        fastestLap: "Max Verstappen",
      },
      "21": {
        pole: "Max Verstappen",
        first: "Max Verstappen",
        last: "Valtteri Bottas",
        fastestLap: "George Russell",
      },
      "22": {
        pole: "Max Verstappen",
        first: "Max Verstappen",
        last: "Nico Hulkenberg",
        fastestLap: "Max Verstappen",
      },
      "23": {
        pole: "Max Verstappen",
        first: "Max Verstappen",
        last: "Nico Hulkenberg",
        fastestLap: "Charles Leclerc",
      },
    },
  },
  {
    id: 21,
    name: "Chloe F",
    points: 0,
    predictions: {
      "1": {
        pole: "Max Verstappen",
        first: "Max Verstappen",
        last: "Logan Sargeant",
        fastestLap: "George Russell",
      },
      "2": {
        pole: "Max Verstappen",
        first: "Max Verstappen",
        last: "Nico Hulkenberg",
        fastestLap: "Sergio Perez",
      },
      "3": {
        pole: "Max Verstappen",
        first: "Max Verstappen",
        last: "Oscar Piastri",
        fastestLap: "Sergio Perez",
      },
      "4": {
        pole: "Max Verstappen",
        first: "Max Verstappen",
        last: "Valtteri Bottas",
        fastestLap: "Sergio Perez",
      },
      "5": {
        pole: "Charles Leclerc",
        first: "Max Verstappen",
        last: "Valtteri Bottas",
        fastestLap: "George Russell",
      },
      "7": {
        pole: "Carlos Sainz",
        first: "Sergio Perez",
        last: "Logan Sargeant",
        fastestLap: "Sergio Perez",
      },
      "8": {
        pole: "Max Verstappen",
        first: "Max Verstappen",
        last: "Logan Sargeant",
        fastestLap: "Sergio Perez",
      },
      "9": {
        pole: "Max Verstappen",
        first: "Max Verstappen",
        last: "Logan Sargeant",
        fastestLap: "Sergio Perez",
      },
      "10": {
        pole: "Max Verstappen",
        first: "Max Verstappen",
        last: "Logan Sargeant",
        fastestLap: "Max Verstappen",
      },
      "11": {
        pole: "Max Verstappen",
        first: "Max Verstappen",
        last: "Oscar Piastri",
        fastestLap: "Max Verstappen",
      },
      "12": {
        pole: "Max Verstappen",
        first: "Max Verstappen",
        last: "Yuki Tsunoda",
        fastestLap: "Max Verstappen",
      },
      "13": {
        pole: "Lando Norris",
        first: "Max Verstappen",
        last: "Kevin Magnussen",
        fastestLap: "Max Verstappen",
      },
      "14": {
        pole: "Max Verstappen",
        first: "Max Verstappen",
        last: "Logan Sargeant",
        fastestLap: "Max Verstappen",
      },
      "15": {
        pole: "Max Verstappen",
        first: "Max Verstappen",
        last: "Kevin Magnussen",
        fastestLap: "Max Verstappen",
      },
      "16": {
        pole: "Max Verstappen",
        first: "Max Verstappen",
        last: "Kevin Magnussen",
        fastestLap: "Max Verstappen",
      },
      "17": {
        pole: "Max Verstappen",
        first: "Max Verstappen",
        last: "Logan Sargeant",
        fastestLap: "Max Verstappen",
      },
      "18": {
        pole: "Max Verstappen",
        first: "Max Verstappen",
        last: "Kevin Magnussen",
        fastestLap: "Max Verstappen",
      },
      "19": {
        pole: "Max Verstappen",
        first: "Max Verstappen",
        last: "Daniel Ricciardo",
        fastestLap: "Max Verstappen",
      },
      "20": {
        pole: "Max Verstappen",
        first: "Max Verstappen",
        last: "Kevin Magnussen",
        fastestLap: "Max Verstappen",
      },
      "21": {
        pole: "Max Verstappen",
        first: "Max Verstappen",
        last: "Yuki Tsunoda",
        fastestLap: "Max Verstappen",
      },
      "22": {
        pole: "Charles Leclerc",
        first: "Max Verstappen",
        last: "Kevin Magnussen",
        fastestLap: "Max Verstappen",
      },
      "23": {
        pole: "Charles Leclerc",
        first: "Max Verstappen",
        last: "Kevin Magnussen",
        fastestLap: "Max Verstappen",
      },
    },
  },
  {
    id: 22,
    name: "Charlie R",
    points: 0,
    predictions: {
      "1": {
        pole: "Charles Leclerc",
        first: "Max Verstappen",
        last: "Guanyu Zhou",
        fastestLap: "Max Verstappen",
      },
      "2": {
        pole: "Max Verstappen",
        first: "Max Verstappen",
        last: "Nico Hulkenberg",
        fastestLap: "Max Verstappen",
      },
      "3": {
        pole: "Max Verstappen",
        first: "Max Verstappen",
        last: "Oscar Piastri",
        fastestLap: "Max Verstappen",
      },
      "4": {
        pole: "Sergio Perez",
        first: "Max Verstappen",
        last: "Guanyu Zhou",
        fastestLap: "Max Verstappen",
      },
      "5": {
        pole: "Sergio Perez",
        first: "Max Verstappen",
        last: "Oscar Piastri",
        fastestLap: "Charles Leclerc",
      },
      "7": {
        pole: "Max Verstappen",
        first: "Max Verstappen",
        last: "Logan Sargeant",
        fastestLap: "Charles Leclerc",
      },
      "8": {
        pole: "Max Verstappen",
        first: "Max Verstappen",
        last: "Logan Sargeant",
        fastestLap: "Fernando Alonso",
      },
      "9": {
        pole: "Max Verstappen",
        first: "Max Verstappen",
        last: "Logan Sargeant",
        fastestLap: "Max Verstappen",
      },
      "10": {
        pole: "Max Verstappen",
        first: "Max Verstappen",
        last: "Logan Sargeant",
        fastestLap: "Lewis Hamilton",
      },
      "11": {
        pole: "Max Verstappen",
        first: "Max Verstappen",
        last: "Logan Sargeant",
        fastestLap: "Max Verstappen",
      },
      "12": {
        pole: "Max Verstappen",
        first: "Max Verstappen",
        last: "Logan Sargeant",
        fastestLap: "Max Verstappen",
      },
      "13": {
        pole: "Lewis Hamilton",
        first: "Max Verstappen",
        last: "Logan Sargeant",
        fastestLap: "Lando Norris",
      },
      "14": {
        pole: "Max Verstappen",
        first: "Max Verstappen",
        last: "Logan Sargeant",
        fastestLap: "Max Verstappen",
      },
      "15": {
        pole: "Max Verstappen",
        first: "Max Verstappen",
        last: "Kevin Magnussen",
        fastestLap: "Sergio Perez",
      },
      "16": {
        pole: "Max Verstappen",
        first: "Max Verstappen",
        last: "Kevin Magnussen",
        fastestLap: "Carlos Sainz",
      },
      "17": {
        pole: "Carlos Sainz",
        first: "Max Verstappen",
        last: "Kevin Magnussen",
        fastestLap: "Carlos Sainz",
      },
      "18": {
        pole: "Max Verstappen",
        first: "Max Verstappen",
        last: "Logan Sargeant",
        fastestLap: "Max Verstappen",
      },
      "19": {
        pole: "Max Verstappen",
        first: "Max Verstappen",
        last: "Kevin Magnussen",
        fastestLap: "Max Verstappen",
      },
      "20": {
        pole: "Lewis Hamilton",
        first: "Max Verstappen",
        last: "Daniel Ricciardo",
        fastestLap: "Max Verstappen",
      },
      "21": {
        pole: "Max Verstappen",
        first: "Max Verstappen",
        last: "Kevin Magnussen",
        fastestLap: "Max Verstappen",
      },
      "22": {
        pole: "Max Verstappen",
        first: "Max Verstappen",
        last: "Guanyu Zhou",
        fastestLap: "Max Verstappen",
      },
      "23": {
        pole: "Max Verstappen",
        first: "Max Verstappen",
        last: "Kevin Magnussen",
        fastestLap: "Max Verstappen",
      },
    },
  },
  {
    id: 23,
    name: "Lee R",
    points: 0,
    predictions: {
      "1": {
        pole: "Max Verstappen",
        first: "Max Verstappen",
        last: "Oscar Piastri",
        fastestLap: "George Russell",
      },
      "2": {
        pole: "Max Verstappen",
        first: "Max Verstappen",
        last: "Lando Norris",
        fastestLap: "Charles Leclerc",
      },
      "3": {
        pole: "Max Verstappen",
        first: "Max Verstappen",
        last: "Yuki Tsunoda",
        fastestLap: "Fernando Alonso",
      },
      "4": {
        pole: "Sergio Perez",
        first: "Max Verstappen",
        last: "Valtteri Bottas",
        fastestLap: "Charles Leclerc",
      },
      "5": {
        pole: "Sergio Perez",
        first: "Sergio Perez",
        last: "Nico Hulkenberg",
        fastestLap: "Max Verstappen",
      },
      "7": {
        pole: "Max Verstappen",
        first: "Max Verstappen",
        last: "Valtteri Bottas",
        fastestLap: "Max Verstappen",
      },
      "8": {
        pole: "Max Verstappen",
        first: "Max Verstappen",
        last: "Kevin Magnussen",
        fastestLap: "Sergio Perez",
      },
      "9": {
        pole: "Max Verstappen",
        first: "Max Verstappen",
        last: "Kevin Magnussen",
        fastestLap: "Sergio Perez",
      },
      "10": {
        pole: "Max Verstappen",
        first: "Max Verstappen",
        last: "Kevin Magnussen",
        fastestLap: "Lewis Hamilton",
      },
      "11": {
        pole: "Max Verstappen",
        first: "Max Verstappen",
        last: "Kevin Magnussen",
        fastestLap: "Lewis Hamilton",
      },
      "12": {
        pole: "Max Verstappen",
        first: "Max Verstappen",
        last: "Kevin Magnussen",
        fastestLap: "Lando Norris",
      },
      "13": {
        pole: "Lando Norris",
        first: "Max Verstappen",
        last: "Kevin Magnussen",
        fastestLap: "Lando Norris",
      },
      "14": {
        pole: "Max Verstappen",
        first: "Max Verstappen",
        last: "Kevin Magnussen",
        fastestLap: "Lando Norris",
      },
      "15": {
        pole: "Max Verstappen",
        first: "Max Verstappen",
        last: "Kevin Magnussen",
        fastestLap: "Lewis Hamilton",
      },
      "16": {
        pole: "Sergio Perez",
        first: "Max Verstappen",
        last: "Nico Hulkenberg",
        fastestLap: "Charles Leclerc",
      },
      "17": {
        pole: "George Russell",
        first: "Lando Norris",
        last: "Nico Hulkenberg",
        fastestLap: "Lewis Hamilton",
      },
      "18": {
        pole: "Sergio Perez",
        first: "Max Verstappen",
        last: "Nico Hulkenberg",
        fastestLap: "Lewis Hamilton",
      },
      "19": {
        pole: "Lando Norris",
        first: "Lando Norris",
        last: "Daniel Ricciardo",
        fastestLap: "Max Verstappen",
      },
      "20": {
        pole: "Lando Norris",
        first: "Lando Norris",
        last: "Nico Hulkenberg",
        fastestLap: "Oscar Piastri",
      },
      "21": {
        pole: "Max Verstappen",
        first: "Max Verstappen",
        last: "Nico Hulkenberg",
        fastestLap: "Lewis Hamilton",
      },
      "22": {
        pole: "Lando Norris",
        first: "Max Verstappen",
        last: "Nico Hulkenberg",
        fastestLap: "Lewis Hamilton",
      },
      "23": {
        pole: "Carlos Sainz",
        first: "Max Verstappen",
        last: "Nico Hulkenberg",
        fastestLap: "Sergio Perez",
      },
    },
  },
];

export const players = getPlayersWithPoints(playersWithoutPoints, races);
